import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Recaptcha from "react-recaptcha";
import {
  Alert,
  Button,
  Checkbox,
  Comment,
  Divider,
  Input,
  notification,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { navigate } from "gatsby";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
const { TextArea } = Input;

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

function ContactForm({ message }) {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Formik
      initialValues={{ fullName: "", email: "", message: "" }}
      initialErrors={{
        fullName: "Uw naam..",
      }}
      validate={(values) => {
        const errors = {};
        // if (!values.acceptTerms) {
        //   errors.acceptTerms = "This is required";
        // }
        if (!values.fullName) {
          errors.fullName = "Een naam is verplicht!";
        }
        // else if (values.fullName.length <= 1) {
        //   errors.fullName = "Your name must be at least 2 characters";
        // }
        if (!values.message) {
          errors.message = "Een bericht is verplicht!";
        }
        if (!values.email) {
          errors.email = "Een e-mailadres is verplicht";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Dit e-mailadres lijkt niet correct.";
        }
        return errors;
      }}
      onSubmit={(data) => {
        if (token !== null) {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": "contact-form",
              ...data,
              "g-recaptcha-response": token,
            }),
          })
            .then(() => {
              // notification.open({
              //   message: "Thank you!",
              //   duration: 5,
              //   type: "success",
              //   description: "We will get back to you shortly.",
              // });
              store.addNotification({
                title: "Thank you!",
                message: "We will get back to you shortly.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
              });
              navigate("/");
            })
            .catch((error) => {
              notification.open({
                message: "Ah oh!",
                duration: 5,
                type: "error",
                description: { error },
              });
            });
        }
      }}
    >
      {(props) => (
        <Form
          name="contact-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
        >
          <Field type="hidden" name="form-name" />
          <Field type="hidden" name="bot-field" />
          <ReactNotification />
          <label
            htmlFor="fullName"
          >
            Naam:
          </label>
          <ErrorMessage
            name="fullName"
            render={(msg) => (
              <Alert
                message={msg}
                style={{ fontSize: "12px" }}
                type="error"
                showIcon
              />
            )}
          />
          <Field
            name="fullName"
            as={Input}
            placeholder="Vul uw naam in"
            type="text"
            style={{ marginBottom: "16px" }}
          />

          <label
            htmlFor="email"
          >
            E-mail adres:
          </label>
          <ErrorMessage
            name="email"
            render={(msg) => (
              <Alert
                message={msg}
                style={{ fontSize: "12px" }}
                type="error"
                showIcon
              />
            )}
          />
          <Field
            name="email"
            as={Input}
            placeholder="Vul uw e-mailadres in"
            type="text"
            style={{ marginBottom: "16px" }}
          />

          <label
            htmlFor="organisation"
          >
            Organisatie/ Bedrijf:
          </label>
          <ErrorMessage
            name="organisation"
            render={(msg) => (
              <Alert
                message={msg}
                style={{ fontSize: "12px" }}
                type="error"
                showIcon
              />
            )}
          />
          <Field
            name="organisation"
            as={Input}
            type="text"
            rows={4}
            style={{ marginBottom: "16px" }}
          />

          <label
            htmlFor="message"
          >
            Uw bericht:
          </label>
          <ErrorMessage
            style={{ marginBottom: "16px" }}
            name="message"
            render={(msg) => (
              <Alert
                message={msg}
                style={{ fontSize: "12px" }}
                type="error"
                showIcon
              />
            )}
          />
          <Field
            name="message"
            as={TextArea}
            placeholder="Vul uw bericht in"
            rows={4}
          />
          {/* <label
            htmlFor="acceptTerms"
            style={{
              display: "block",
              fontSize: "14px",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            <Field
              name="acceptTerms"
              as={Checkbox}
              type="checkbox"
              style={{ marginRight: "8px" }}
            />{" "}
            I understand that the information provided by me is subject to the
            <br />
            <a
              href="https://www.iubenda.com/privacy-policy/16163444"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a>
          </label>
          <ErrorMessage
            name="acceptTerms"
            render={(msg) => (
              <Alert
                message={msg}
                style={{ fontSize: "12px" }}
                type="error"
                showIcon
              />
            )}
          /> */}
          {props.isValid && (
            <Recaptcha
              sitekey="6LeN0bAUAAAAAO--qst6xf0K8OwGTOc64yh0AMyp"
              render="explicit"
              verifyCallback={(response) => {
                setToken(response);
              }}
              onloadCallback={() => {
                console.log("done loading!");
              }}
              style={{ marginTop: "12px" }}
            />
          )}
          <Button
            type="primary"
            size="large"
            disabled={!props.isValid}
            // loading={props.isSubmitting}
            htmlType="submit"
            style={{ marginTop: "16px" }}
            // icon={<SendOutlined />}
          >
            Versturen..
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default ContactForm;