import React from "react";
import ContactForm from "./ContactForm";

const Contact = (props) => (
  <section id="contact">
    <div className="inner">
      <section>
        {/* <form method="post" action="#">
          <div className="field half first">
            <label htmlFor="name">Naam:</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">E-mailadres:</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Bericht:</label>
            <textarea name="message" id="message" rows="6"></textarea>
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Versturen" className="special" />
            </li>
          </ul>
        </form> */}
        <ContactForm />
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>E-mailadres</h3>
            <a href="#">info@mecosud.nl</a>
          </div>
        </section>
        {/* <section>
          <div className="contact-method">
            <span className="icon alt fa-phone"></span>
            <h3>Phone</h3>
            <span>(000) 000-0000 x12387</span>
          </div>
        </section> */}
        <section>
          <div className="contact-method">
            <span className="icon alt fa-home"></span>
            <h3>Postadres</h3>
            <span>
              Alida de Jongstraat 11
              <br />
              2401KS, Alphen aan den Rijn
              <br />
              Nederland
            </span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-info"></span>
            <h3>Info</h3>
            <span>
              Bank: NL72 INGB 0678 7111 19
              <br />
              Kvk: Leiden 28044549
              <br />
              BTW: NL 0092.48.146.B01
            </span>
          </div>
        </section>
      </section>
    </div>
  </section>
);

export default Contact;
